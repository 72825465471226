.component-badger-accordion .badger-accordion__panel{
  max-height:0
}
.badger-accordion__panel{
  max-height:75vh;
  overflow:hidden
}
.badger-accordion__panel.-ba-is-hidden{
  max-height:0 !important
}
.badger-accordion--initialized .badger-accordion__panel{
  -webkit-transition:max-height ease-in-out 0.2s;
  transition:max-height ease-in-out 0.2s
}
.badger-accordion__header .js-badger-accordion-header .badger-accordion-toggle{
  width:100%;
  background:transparent;
  border:0;
  -webkit-box-shadow:none;
          box-shadow:none;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center
}
.badger-accordion__header .js-badger-accordion-header .badger-accordion-toggle .badger-accordion-title{
  -webkit-box-flex:0;
      -ms-flex:0 0 90%;
          flex:0 0 90%;
  cursor:pointer;
  text-align:left
}
.badger-accordion__header .js-badger-accordion-header .badger-accordion-toggle .badger-toggle-indicator{
  -webkit-box-flex:0;
      -ms-flex:0 0 10%;
          flex:0 0 10%;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:end;
      -ms-flex-pack:end;
          justify-content:flex-end;
  cursor:pointer
}


/*# sourceMappingURL=chunk-vendors.cfd5ad2b.css.map*/